<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isHelpSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-help-sidebar-active', val)"
    >
      <template>
        <!-- Header -->
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          "
        >
          <h3 class="mb-0">
            {{ title }}
          </h3>
        </div>
        <!-- Body -->
        <div class="mt-1" v-html="content"></div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { BSidebar } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BSidebar,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isHelpSidebarActive",
    event: "update:is-help-sidebar-active",
  },
  props: {
    isHelpSidebarActive: {
      type: Boolean,
      required: true,
    },
    content: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
.b-sidebar-body img {
  max-width: 100%;
}
.b-sidebar-body {
  padding: 10px;
}
</style>