<template>
  <component :is="loaded === false ? 'div' : 'div'">
     <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        action-delete
        action-refresh
        @back="hRedirect('apps-helps-list')"
        @delete="helperDeleteItem(`/help-areas/${currentItem.id}`, null, 'apps-helps-list')"
        @save="updateItem"
        @refresh="getItem"
        :title="this.currentItem.name"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.stop.prevent="handleSubmit(updateItem)" @reset.prevent="resetForm">
        <b-card>
            <div class="mb-2">
            <b-form-checkbox v-model="currentItem.status" value="1" name="status" class="custom-control-success">
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>
          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <b-form-group :label="$t('Platform')">
                <b-form-select v-model="currentItem.platform" :options="platform">
                </b-form-select>

                  <small v-if="validate && Object.keys(validation).includes('platform')" class="text-danger"
                  >{{ $t(validation.platform[0]) }}</small>
                </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
                <b-form-group :label="$t('Slug')">
                    <b-form-input v-model="currentItem.slug" id="slug" :placeholder="$t('Slug')"/>
                  <small v-if="validate && Object.keys(validation).includes('slug')" class="text-danger"
                  >{{ $t(validation.slug[0]) }}</small>
                </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-group :label="$t('Title German')">
               <b-form-input v-model="currentItem.title_german" id="title_german" :placeholder="$t('Title German')"/>
                  <small v-if="validate && Object.keys(validation).includes('title_german')" class="text-danger"
                  >{{ $t(validation.title_german[0]) }}</small>
                </b-form-group>
            </b-col>

            <b-col md="12" xl="12" class="mb-1 mt-2">
                <b-form-group :label="$t('Content German')">
                    <quill-editor v-model="currentItem.content_german" :options="snowOption"/>
                  <small v-if="validate && Object.keys(validation).includes('content_german')" class="text-danger"
                  >{{ $t(validation.content_german[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" xl="12" class="mb-1 mt-2">
              <b-form-group :label="$t('Title English')">
               <b-form-input v-model="currentItem.title_english" id="title_english" :placeholder="$t('Title English')"/>
                </b-form-group>
            </b-col>

            <b-col md="12" xl="12" class="mb-1 mt-2">
                <b-form-group :label="$t('Content English')">
                    <quill-editor v-model="currentItem.content_english" :options="snowOption"/>
                </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="button" class="mr-1" @click="preview">
            {{ $t('Preview') }}
          </b-button>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
    <edit-help-sidebar
        v-model="isHelpSidebarActive"
        :content="helpAreaContent"
        :title="helpAreaTitle"
    />
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadioGroup,
  BFormTimepicker,
  BFormTextarea,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { required, alphaNum, email } from '@validations'
import Cleave from 'vue-cleave-component'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { BCardActions } from '@core/components/b-card-actions'
import EditHelpSidebar from '../components/help-area/EditHelpSidebar.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BFormTimepicker,
    BFormTextarea,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    Cleave,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    EditHelpSidebar,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  computed:{
    platform(){
      return [
        { value: null, text: this.$i18n.t('Please select a platform') },
        {
          value: 'Admin',
          text: this.$i18n.t('Admin'),
        },
        {
          value: 'Expert',
          text: this.$i18n.t('Expert'),
        },
        {
          value: 'Client',
          text: this.$i18n.t('Client'),
        },
        {
          value: 'Affiliate',
          text: this.$i18n.t('Affiliate'),
        },
      ]
    },
    helpAreaContent(){
      if(this.$i18n.locale == "en"){
        return  this.currentItem.content_english ? this.currentItem.content_english : this.currentItem.content_german
      }else{
        return this.currentItem.content_german
      }
    },
    helpAreaTitle(){
      if(this.$i18n.locale == "en"){
        return  this.currentItem.title_english ? this.currentItem.title_english : this.currentItem.title_german
      }else{
        return this.currentItem.title_german
      }
    }
  },
  data() {
    return {
        isHelpSidebarActive:false,
        snowOption: {
          theme: 'snow',
        },
      currentItem: {
        id: '',
        status: 1,
        platform:'Admin',
        slug:'',
        title_german:'',
        content_german:'',
        title_english:'',
        content_english:'',
      },
      validation: {},
      validate: false,
      loaded: true,
    }
  },
  mounted() {
    this.getItem()
  },

  methods: {
    getItem() {
      this.helperGetItem(`/helpareas/${this.$route.params.helpsId}`)
    },
    updateItem() {
      this.loaded = false
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false
            if (success) {
              this.helperUpdateItem(
                  `/helpareas/${this.$route.params.helpsId}`,
                  this.currentItem,
                  function () {
                  },
                  function(error) {
                  }
              )
            }
          })
    },
    deleteItem() {
      this.helperDeleteItem(
          `/helpareas/${this.$route.params.helpsId}`,
          this.currentItem,
          'apps-helps-list'
      )
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },

    preview(){
      this.isHelpSidebarActive=true;
    }
  },
}
</script>

<style>
.ql-container.ql-snow {
    min-height: 150px;
}
</style>
